@import "react-dual-listbox/src/scss/react-dual-listbox.scss";
@import "dnc-colors";

/* Global styling to multiselect */
.msts {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#membership_edit_form {
  width: 100%;
  margin: auto;
}

.form_container {
  margin: auto;
}

.msts__heading,
.msts__subheading,
.msts__body,
.msts__footer {
  display: flex;
}

.msts__side {
  width: 50%;
}

.msts__side_controls {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.msts__list {
  padding: 0;
  margin: 0;
}

.msts__list-item {
  list-style-type: none;
  cursor: pointer;
}

.msts__list-item_disabled {
  cursor: default;
}

/* Our specific theme */
.msts_dnc {
  border: 1px solid $dnc-grey-dark;

  .msts__heading .msts__side {
    padding: 5px;
    text-align: center;
    color: $black;
    font-weight: bold;
  }

  .msts__heading .msts__side_available {
    background-color: $dnc-grey;
    color: $red;
    font-family: $dnc-font-bold;
  }

  .msts__heading .msts__side_selected {
    background-color: $dnc-grey;
  }

  .msts__subheading .msts__side_filter {
    padding: 5px;
  }

  .msts__body .msts__side_available {
    border-right: 1px solid $dnc-grey-dark;
  }

  .msts__footer .msts__side {
    padding: 5px 15px;
    background-color: $dnc-grey;
    font-size: 0.75em;
    font-weight: bold;
    color: $black;
    text-align: left;
  }

  .msts__list-item {
    padding: 5px 10px;
    transition: background-color ease-in 0.1s, color ease-in 0.1s;
  }

  .msts__list-item:hover {
    background-color: $dnc-blue-light;
    color: $black;
  }

  .msts__list-item_disabled {
    background-color: $dnc-grey;
    color: $dnc-grey-dark;
  }

  .msts__list-item_disabled:hover {
    background-color: $dnc-grey;
    color: $dnc-grey-dark;
  }

  .msts__list-item_highlighted {
    background-color: $dnc-blue-light;
  }

  .msts__control {
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px 3px;
    color: $dnc-grey;
    transition: color ease 0.15s;
  }

  .msts__control:hover {
    color: $dnc-grey-dark;
  }

  .msts__control[disabled] {
    color: $dnc-grey;
  }

  .msts__control_select-all:after {
    content: "❯";
  }

  .msts__control_deselect-all:after {
    content: "❮";
  }

  .msts__filter {
    position: relative;
  }

  .msts__filter-input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid $dnc-grey-dark;
  }

  .msts__filter-clear {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 10px;
    font-size: 1.25em;
    color: $dnc-grey;
    transition: color ease 0.15s;
  }

  .msts__filter-clear:after {
    content: "×";
    vertical-align: middle;
  }

  .msts__filter-clear:hover {
    color: $portal-accent-red;
  }

  .msts_disabled {
    background-color: $dnc-grey;
  }
}

.rdl-list-box {
  border: 1px solid $grey;
}

.rdl-control-label {
  padding: 10px 12px;
  background-color: $dnc-grey;
  margin: 0;
}

.rdl-filter {
  margin: 0;
}

.rdl-available label {
  color: $red;
}

.rdl-move {
  padding: 5px 0;
  border: none;
}

.rdl-control {
  padding: 0px;
}

.rdl-control-container {
  height: 415px;
  overflow-y: auto;
  overflow-x: hidden;

  option {
    padding: 10px;
  }
}

.rdl-has-header.rdl-has-filter .rdl-actions {
  padding-top: 0;
}

.rdl-move-all.rdl-move-right {
  margin-bottom: 50px;
}

.rdl-move-all.rdl-move-left {
  margin-top: 50px;
}

.flipped {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
