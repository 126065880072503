.dnc-mui-button {
    &.MuiButton-root {
        &.blue-primary-background {
            background-color: $dnc-blue-primary;
        }

        &.blue-primary-outline {
            border-color: $dnc-blue-primary;
        }
    }
}