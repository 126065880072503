.dataset-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dataset-search {
  width: 20rem;

  input {
    width: 84%;
  }

  .btn-outline-secondary {
    border: 1px solid rgba($black, 0.87);
    color: rgba($black, 0.87);
  }
}

.dataset-facets {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
