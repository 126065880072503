/* Overrides to material-ui’s Box-related component styling. */

@import "dnc-colors";
@import "dnc-fonts";


.dnc-mui-box {
    &.MuiBox-root {
        *:not(.MuiIcon-root) {
            font-family: $dnc-font-normal;
        }
    
        .dnc-header-text {
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;

            &.capitalize {
                text-transform: capitalize;
            }
        }

        &.blue-primary-background {
            background-color: $dnc-blue-primary;
        }

        &.blue-primary-outline {
            border-color: $dnc-blue-primary;
        }
    }
}
