/* Overrides to material-ui’s Container-related component styling. */

@import "dnc-colors";
@import "dnc-fonts";

.dnc-mui-container {
    &.MuiContainer-root {
        box-shadow: none;
    
        *:not(.MuiIcon-root) {
            font-family: $dnc-font-normal;
            font-size: inherit;
        }
    
        .dnc-header-text {
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}