/**
 * Use this file to add any custom Portal styles, or any Material Design
 * components not defined by bootstrap-material-design.
 *
 * These styles are applied to all pages. For page or component specific
 * styles, add them to a separate file.
 *
 * Portal-specific selectors are written in BEM format to differentiate them
 * from third-party (Bootstrap) styles: http://getbem.com/naming/
 */

@import "dnc-colors";
@import "dnc-fonts";

// TODO: See https://github.com/democrats/portal/compare/yehp/4329-bootstrap-prototype?expand=1
// for filling out this file. We're starting from scratch to make sure every
// style is truly needed.

/**
 * VARIABLES
 */
$height-navbar: 72px;
$width-sidebar: calc(
  100px + 14rem
); // px + rem accommodates the fixed-size logo and scalable menu font
$body-line-height: 1.5; // As set by Bootstrap
$min-width-button: 100px;

/**
 * OVERALL STYLES
 */

body {
  background-color: $gray-100;
  font-size: 110%;
  font-family: $dnc-font-normal;
}
b,
strong {
  font-family: $dnc-font-bold;
  font-weight: normal; // Lets our bold font render correctly in Firefox and Safari
}
section {
  margin: 0 0 3rem;
}

// Allows the page's flex layout, particularly the sidebar having a background
// color, to extend the full height of the screen
.wrapper {
  min-height: calc(100vh - #{$height-navbar});
}

/**
 * HEADER STYLES
 */

.header__container {
  background-color: white;
  @include media-breakpoint-down(xs) {
    background-color: $dnc-blue-primary;
  }
}
.header__container--side {
  width: $width-sidebar;
  @include media-breakpoint-down(xs) {
    width: auto;
  }
}
// The DNC logo in the header
.header__logo {
  width: 40px;
  height: 40px;
}
// The "Portal" text next to the DNC logo
.header__title {
  color: $white;
  font-family: $dnc-font-bold;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin: 3px 0 -3px; // vertical alignment adjustment
}
// Links in the header
.header__nav-link {
  color: $black;
  font-size: 0.8rem;
}
// Responsive 'sidebar' menu button for mobile
.header__nav-link--menu {
  color: $white;
  line-height: 9px;
  cursor: pointer;
  .material-icons:not(.MuiIcon-root) {
    font-size: 2rem;
  }
}

/**
 * SIDEBAR STYLES
 */

.sidebar__container {
  background-color: $dnc-blue-secondary;
  @include media-breakpoint-up(sm) {
    width: $width-sidebar;
  }
}
/* Change sidebar/nav brand width when sidebar is collapsed */
.sidebar-collapsed {
  width: 80px;
}
// Sidebar menu items
.sidebar__list-group-item {
  color: $gray-100;
  font-size: 1.5rem;
  border-bottom: 1px solid $dnc-blue-primary-alpha20;
  :first-child {
    margin-right: 0rem;
  }
  padding: 1.5rem;
  &:hover {
    color: $white;
    text-decoration: none;
    background-color: $dnc-blue-primary-alpha20;
  }
  .material-icons:not(.MuiIcon-root) {
    margin: 0 1rem 0 0; // adjust horizontal position of icons
    font-size: 1.5rem;
  }
}
// Active sidebar menu item
.sidebar__list-group-item--active {
  @extend .sidebar__list-group-item;
  background-color: $dnc-blue-dark;
  border-color: $dnc-blue-primary-alpha20;
  // TODO: Revisit/refactor to reduce nesting. Relates to _sidebar.html.
  a {
    color: $gray-300;
    text-decoration: none;
    display: flex; // enable use of align-items
    align-items: center; // align the text vertically with the icon
    width: 100%; // prevent having multiple menu items on one line
    &:hover {
      color: $white;
    }
  }
  &:hover {
    color: $white;
    background-color: $dnc-blue-dark;
  }
}
// Sidebar subnavigation item
.sidebar__subgroup-item {
  color: $gray-100;
  font-size: 1rem;
  line-height: 1.3;
  padding: 2px 0;
  padding-left: 2.5rem; // Account for icon spacing
  margin-bottom: 1rem;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}
.sidebar__subgroup-item--active {
  @extend .sidebar__subgroup-item;
  font-family: $dnc-font-bold;
}

// Sidebar collapse/expand button
.sidebar__nav-link--collapse {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  // Float it all the way down and right (top and left: auto)
  // But with some spacing (bottom and right: 10px)
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: 10px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.sidebar__nav-link--collapse-text {
  color: $gray-100;
  font-size: 1.5rem;
}
.sidebar__nav-link--collapse-icon {
  display: flex;
  align-items: center;
  color: $dnc-blue-secondary;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
  border-radius: 50%; // circle background
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  .material-icons:not(.MuiIcon-root) {
    font-size: 2rem;
  }
}

// Center the expand/collapse button when the sidebar is collapsed
.sidebar-collapsed .sidebar__nav-link--collapse {
  margin-left: auto;
  margin-right: auto;
}

// Hide sidebar text when sidebar is collapsed
.sidebar-collapsed .menu-collapsed, .sidebar-collapsed .sidebar-submenu {
  display: none;
}

.sidebar-collapsed .list-group-item .material-icons {
  margin: 0px;
}

.flip-arrow::before {
  content: "chevron_left";
}

.sidebar-collapsed .flip-arrow::before {
  content: "chevron_right";
}

/**
 * CONTENT AREA STYLES
 */

// Similar to a typical card, but is used to contain most of a page's content,
// which covers a larger space. For balance with the larger area, we increase
// the padding from the default card style.
.card--page {
  @extend .card; // from bootstrap-material-design
  padding: 32px 48px;
  // TODO: Add a responsive max-width?
  // Also as a convention, we might use this style to contain max-width
  // content, whereas putting content directly on the body means it can
  // scale to full width.
}

// A clickable card that gets its elevation raised on hover per Material
// Design behavior. Used for "app cards" on the Portal homepage.
.card--btn {
  @extend .card, .btn, .btn-raised;
  background-color: $white;
  &:hover {
    background-color: $white;
  }
  // These properties undo some bootstrap-material-design button styles
  padding: 0;
  text-align: left;
  text-transform: none;
}

// An "app card" that represents a Phoenix job, such as exporting or importing.
.card__img--job {
  background-color: $gray-200;
  color: $white;
  width: 128px;
  min-height: 128px;
  height: auto;
  text-align: center;
  // size and vertical position for the icon; use extra specificity to
  // override default
  i.material-icons {
    font-size: 64px;
    line-height: 127px;
    opacity: 0.75; // TODO: Remove after design discussion.
  }
  // colors for different Portal job types
  &-export {
    @extend .card__img--job;
    // TODO: Re-add or delete after design discussion.
    // background-color: $job-color-export;
  }
  &-import {
    @extend .card__img--job;
    // TODO: Re-add or delete after design discussion.
    // background-color: $job-color-import;
  }
  &-other {
    @extend .card__img--job;
    // TODO: Re-add or delete after design discussion.
    // background-color: $job-color-other;
  }
  &-tracker {
    @extend .card__img--job;
    background-image: url("/static/portal/img/task_voterfile_status.svg");
    background-repeat: no-repeat;
    background-position: 50%;

  }
}
// Styles the text before or on top of the card's title
.card__title--category {
  font-family: $dnc-font-bold;
  text-transform: uppercase;
}

// Positions top-level actions on the right side of the card
// complement for left-side h2 sized card heading
.card__actions--right {
  text-align: right;
  line-height: 2rem;
  span {
    vertical-align: middle;
  }
}
// Removes underline from card action links
.card__action:hover {
  text-decoration: none;
}

.service-card {
  min-width: 280px;
}

/**
 * FORMS
 */

// Limit how wide form content expands
.form__container {
  max-width: 768px;
}
// Spacing adjustments for form headings for better hierarchy/grouping
.form__container h3 {
  margin: 3rem 0 1.5rem;
}
.form__container h4 {
  margin: 2.5rem 0 1.5rem;
}
.form__container h5 {
  margin: 2rem 0 1.5rem;
}
// Allow for form sections containing many .form-group
.form__container .form__section {
  margin: 1rem 0 3rem;
}
// The gray task container within the job form
.form__container .job__task {
  background-color: $gray-100;
  padding: 2rem;
  margin-top: 1rem;
}
// The task type child component within TaskForm
.form__container .job__task-type {
  margin-top: 3rem;
}
// The button set container for a job's save/run actions
.form__container .job__actions {
  margin: 4rem 0 2rem;
}
// For consistency, set a min-width for job/task buttons (OK, Save, Cancel, etc.)
// (We don't want to apply this to all buttons in a form since there are circle-
// style buttons, etc.)
.form__container .job__actions .btn,
.form__container .task__actions .btn {
  min-width: $min-width-button;

  &.btn--icon {
    margin-top: -0.5rem; // vertically align icon-button with text-buttons
    min-width: auto;
  }
}

// Custom checkboxes used by our react DayField
// Adapted from v0 Portal UI with a btn-y flare
// TODO - restyle with UX designer input
.checkbox__collection label {
  font-family: $dnc-font-normal;
}
.checkbox__label {
  // bootstrap button box-shadow styles
  @extend .btn, .btn-raised;
  border-radius: 50%;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  line-height: 41px; // offsets box-shadow
  padding: 0;
  @include media-breakpoint-down(sm) {
    margin-right: 8px;
  }
}
.checkbox__label--selected {
  @extend .checkbox__label, .bg-secondary, .text-light;
  border: none;
}
// Display Date field + Time field inline for react DateTimeFieldset
.datetime__set--inline {
  @extend .flex-fill;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
  // override react-datepicker library default style
  .react-datepicker-wrapper {
    width: 100%;
  }
}

// tell react-datepicker library to hide disabled times values
li.react-datepicker__time-list-item--disabled {
  display: none;
}

// TODO: Reconsider this once UI v1 rollout is complete
// Bootstrap Material Design relies on the :invalid pseudoclass
// which we cannot use unless we refactor our React form error handling
// Currently empty inputs would be :invalid on page-load which is
// a poor user experience
.form-control.error,
.custom-file-control.error {
  @extend .border-danger;
}

// Fix list indentation for errors inside .alert divs
.alert ul.errorlist {
  padding-left: 3rem;
  margin-bottom: 0;
}
// Match inline form errors with top of form alert-style errors
.form-group ul.errorlist {
  @extend .alert, .alert-error, .border-left, .border-danger;
  list-style: none;
  margin: 0.5rem 0;
}

// Keep dropdowns from overlapping on job scheduling
.time_dropdown {
  width: 8rem;
}

// Control hourly_step on job scheduling
.step-choices{
  width: 10rem;
  margin-left: 2em;
}

/**
 * JOB DETAILS
 *
 * TODO: Move to component-specific file
 */

.job__details {
  // TODO: consolidate with card__page padding (and use variables?)
  // This is just a quick fix that avoids regression testing other pages.
  padding: 2rem 4rem 4rem;
}
.job__details .job__title {
  margin: 2rem 0 3rem;
}
.job__details code {
  white-space: pre-wrap;
}

// Right-aligned action links used above .card and left of .breadcrumb
.nav__actions--right {
  @extend .justify-content-end;
  @extend .nav;
  padding: 0.75rem 0rem; // Match .breadcrumb vertical
  font-family: $dnc-font-bold;
}
.nav__action {
  padding-left: 1.5rem;

  // Make material icon and action label sizing match
  span {
    line-height: 1.5rem;
    font-size: 1rem;
    vertical-align: middle;
    padding-left: 0.25rem;
  }

  a:hover {
    text-decoration: none;
  }
}
// Specific overrides to match action btns to action links
.btn--nav-action {
  @extend .btn-link;
  color: $link-color;
  text-transform: none; // no uppercase
  margin: 0;
  padding: 0;
}
.btn--nav-action:hover {
  color: $link-hover-color;
  text-decoration: none; // no underline
}

// Align material icons with text by default
// Material UI takes care of that for us, so exclude those icons from our
// rules
.material-icons:not(.MuiIcon-root) {
  font-size: 1rem;
  line-height: $body-line-height;
  vertical-align: middle;
  // Allow Bootstrap's display-* classes to override sizing
  &.display-1 {
    @extend .display-1;
  }
  &.display-2 {
    @extend .display-2;
  }
  &.display-3 {
    @extend .display-3;
  }
  &.display-4 {
    @extend .display-4;
  }

  // or use a normalized class
  &.icon--small {
    font-size: 1rem;
  }
  &.icon--medium {
    font-size: 1.5rem;
  }
  &.icon--large {
    font-size: 2.5rem;
  }
}

// Increase the click area of the Bootstrap Material Design "icon button"
.btn--icon {
  @extend .btn;
  @extend .bmd-btn-icon;
  width: 3rem;
  height: 3rem;
}

.table--align-middle td {
  vertical-align: middle;
}

// Extend BMD *-danger styles to *-error suffix so Django's
// messages.error tags can be used to define alert class in templates
.border-error {
  @extend .border-danger;
}
.alert.alert-error {
  @extend .alert-danger;
}
// Vertically align .alert content with alert icons
.alert span:not(.portal__icon) {
  vertical-align: middle;
}
// Overrides the !important font-color applied by .text-muted when the
// element is inside an .alert message
// This is specifically relevant to errored jobs in the job list template
// but is reasonable to apply generally
.alert .text-muted {
  color: inherit !important;
}

// Hide job status title copy by default
// (to avoid repetition and UI crowding)
.card__status--copy {
  display: none;
  padding-left: 0.25rem;
  vertical-align: middle;
}
// But display the copy on the job detail status card
.card__status--detailed .card__status--copy {
  display: inline-block;
}

/* Datetime strings are initially invisible to the browser, then displayed when
   formatted for local time */
.js-datetime-to-local {
  visibility: hidden;
}

// A white box for containing text on the Job Details page
.job-detail__container {
  background-color: #fff;
  margin: 0 0 0.5rem;
  padding: 1.5rem;

  // Allow any status icons to behave with the same height as the text beside it
  .material-icons {
    line-height: 1rem;
  }
}

// Add spacing to give tasks some breathing room
.job-detail__task-wrapper {
  margin: 2rem 2rem 2rem 0;
}

.job-detail__task {
  background-color: $gray-100;
  padding: 1rem;

  section {
    margin: 0 0 1.5rem;
  }
}

// Spacing adjustments for the expandable task details
.job-detail__task-content {
  border-top: 1px solid $dnc-grey; /* serves as a separator between the task summary and details */
  padding: 2rem 0 0;

  section {
    font-size: 0.9rem;
    margin: 0 0 2rem 3.5rem;
    overflow-wrap: break-word; /* break long text like URLs onto multiple lines */
  }
}

// Accordion handling
.job-detail__accordion {
  line-height: 0;
  color: $black;
}
.accordion-toggle {
  i::after {
    content: "expand_less";
  }
  &.collapsed {
    i::after {
      content: "expand_more";
    }
  }
}

// Colors for task type icons.
/* The default 40px circle around an icon used in Material Design lists:
https://material-components.github.io/material-components-web-catalog/#/component/list */
.list-item__graphic {
  background-color: rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin: 0 4px;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  /* Custom colors for job/task types */
  &.multiple_tasks {
    background-color: $job-color-multiple-tasks;
  }
  &.unknown {
    background-color: $job-color-other;
  }
  &.script_runner {
    background-color: $job-color-script_runner;
  }
  &.sheet_exp {
    background-color: $job-color-sheet_exp;
  }
  &.sql_runner {
    background-color: $job-color-sql_runner;
  }
  &.vanlist_exp {
    background-color: $job-color-vanlist_exp;
  }
  &.van_create_bulk_import {
    background-color: $job-color-van_create_bulk_import;
  }
  &.van_get_bulk_import_result {
    background-color: $job-color-van_get_bulk_import_result;
  }
  &.vanlist_imp {
    background-color: $job-color-vanlist_imp;
  }
  &.vanmapregion_imp {
    background-color: $job-color-vanmapregion_imp;
  }
  &.vantarget_imp {
    background-color: $job-color-vantarget_imp;
  }
  &.voter_file_match {
    // Different dimensions/coloring from other icons
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 65px;
    height: 37px;
  }
}

/* A default style to use SVG files as icons, drawing them as a background image.
To change the size of the icon, adjust width, height, and background-size. */
.portal__icon {
  background-position: center;
  background-repeat: no-repeat;
  /* Make the default image size a bit smaller than the container size so there's
  wiggle room to make tall/wide images visually consistent with the rest */
  background-size: 22px 22px;
  display: inline-block;
  width: 24px;
  height: 24px;
  &--vanlist_imp {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_phoenix.svg");
  }
  &--vanlist_exp {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_van.svg");
    background-size: 21px 21px;
  }
  &--van_create_bulk_import {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_van.svg");
    background-size: 21px 21px;
  }
  &--van_get_bulk_import_result {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_van.svg");
    background-size: 21px 21px;
  }
  &--script_runner {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_script_runner.svg");
    background-size: 23px 23px;
  }
  &--sheet_exp {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_google_sheet.svg");
    background-size: 23px 23px;
  }
  &--sql_runner {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_database.svg");
    background-size: 19px 18px;
  }
  &--voter_file_match {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_voter_file_match.svg");
    background-size: 65px 37px;
    width: 65px;
    height: 37px;
  }
  &--vanmapregion_imp {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_vanmapregion_imp.svg");
  }
  &--vantarget_imp {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_phoenix.svg");
    background-size: 19px 18px;
  }
  &--multiple_tasks {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_multiple.svg");
  }
  &--unknown {
    @extend .portal__icon;
    background-image: url("/static/portal/img/task_unknown.svg");
  }
}

// CARD TABBED SUBNAV
// Tabbed navigation UI for .card elements
.card__tabnav {
  border-bottom: 2px solid $gray-200;
  font-size: 1rem;
  margin: 1.5rem 0;
}
// Inactive tab
.card__tab {
  text-transform: uppercase;
  margin-left: 2rem;
  a {
    display: block;
    padding: 0 0.5rem 0.5rem;
  }
  &.disabled {
    color: $dnc-grey-dark;
  }
  &:first-child {
    margin-left: 0;
  }
}
// Active tab
.card__tab--active {
  @extend .card__tab;
  border-bottom: 2px solid $gray-600;
  a {
    color: $gray-600;
    font-family: $dnc-font-bold;
  }
}
// A description of the tab's contents
.tab__subheading {
  @extend .text-muted;
  margin-bottom: 1.5rem;
}

.help-block {
  @extend .text-muted;
  @extend .font-italic;
}

#config-file-upload {
  display: none;
}

.color-blue-primary {
  color: $dnc-blue-primary;
}

.custom-file-upload {
  cursor: pointer;

  label {
    color: $dnc-blue-primary;
    text-transform: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.github-fetch-link {
  font-size: 1rem;
  margin-right: 1rem;

  .material-icons {
    width: 1rem;
  }
}

.code-highlight {
  font-family: monospace;
  color: $portal-accent-red;
}

.badge-primary {
  background-color:  $dnc-blue-secondary;
  font-size: 1rem;
}

// Labels
.labels-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; // Spacing between labels
  padding-bottom: 20px; // Padding between existing labels and label input box
}

.label-badge .badge {
  display: flex;
  align-items: center;
  gap: 4px; // Space between label name and remove button
  font-size: 16px;
}

.label-remove-btn {
  border: none;
  background: none;
  cursor: pointer;
  color: #FFFFFF; // White

  &:hover {
    color: #00AEF3; // Change color when hovering to indicate clickable button
  }
}

.label-input-container {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 350px;
}

.onboarding-form {
  div {
    border-color: #C4C4C4 !important;
  }

  .form-check label {
    color: $body-color;
    margin-top: $spacer;
  }

  .form-highlight-row {
    background-color: #F9D1D1;
    font-family: $dnc-font-bold;
  }

  .form-highlight-text {
    color: $portal-accent-red;
    font-family: $dnc-font-bold;
  }
}

.w-400 {
  width: 400px;
}
.radio-label  {
  div {
    display: inline-block;
    margin-right: 2em;
    margin-top: 1em;
  }
  label {
    font-family: $dnc-font-normal;
    text-transform: none;
 
  }
}
