/**
 * Color variables for use in Portal.
 *
 * See Bootstrap's color variables for more options:
 * https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
 */

/**
 * DNC BRAND COLORS
 */
$dnc-blue-primary: #00aef3;
$dnc-blue-secondary: #00589c;
$dnc-blue-light: #d9f1ff;
$dnc-blue-dark: #0f2446;
$dnc-blue-primary-alpha20: rgba(0, 174, 243, 0.2);
$dnc-grey: #ecf0f1;
$dnc-grey-dark: #7f8c8d;

/**
 * PORTAL BASE COLORS
 */
$black: #373a3c;

/**
 * PORTAL ACCENT COLORS
 *
 * To avoid confusion in meaning when applied to the UI, these should be
 * different enough from
 *   1) brand colors, and
 *   2) status colors (success, error)
 *
 * TODO: Revisit. These are from the prototype and not the latest mockups.
 */
$portal-accent-purple: #7c86e5;
$portal-accent-teal: #03bfc9;
$portal-accent-red: #e16e97;

// Controls the intensity of alert background colors.
// The default is -10; we lighten things a bit more
// https://github.com/twbs/bootstrap/blob/08ba61e276a6393e8e2b97d56d2feb70a24fe22c/scss/_variables.scss#L1090
$alert-bg-level: -11.5;

// Colors for job type icons.
$job-color-multiple-tasks: #f0689c; // pink
$job-color-other: $black;
$job-color-script_runner: $black;
$job-color-sheet_exp: #21a464; // green
$job-color-sql_runner: $dnc-blue-secondary;
$job-color-vanlist_exp: #6f32a7; // purple
$job-color-van_create_bulk_import:#097969; // green
$job-color-van_get_bulk_import_result:#097969; // green
$job-color-vanlist_imp: $dnc-blue-primary;
$job-color-vanmapregion_imp: $job-color-vanlist_exp;
$job-color-vantarget_imp: $job-color-vanlist_exp;

:export {
  bluePrimary: $dnc-blue-primary;
  blueSecondary: $dnc-blue-secondary;
  grey: $dnc-grey;
  accentPurple: $portal-accent-purple;
}

/**
 * Voter File Match input colors.
 */
$portal-required-field: #ffe8e8;
$portal-soft-required-field: #feffe8;
$portal-optional-field: #eefbee;
