/* Overrides to material-ui’s Table-related component styling. */

@import "dnc-colors";
@import "dnc-fonts";

.dnc-mui-table {
    &.MuiTableContainer-root {
        box-shadow: none;

        *:not(.MuiIcon-root) {
            font-family: $dnc-font-normal;
            font-size: inherit;
        }

        .MuiTableBody-root.dnc-striped-table {
            .MuiTableRow-root:nth-of-type(odd) {
                background-color: $gray-100;
            }
        }

        .MuiTableRow-root.dnc-no-bottom-border {
            border-bottom: none;
            * {
                border-bottom: none;
            }
        }

        .dnc-header-text {
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
