/**
 * This file contains "fixes" for the Bootstrap and bootstrap-material-design
 * themes, including adjustments to better match Google's Material Design spec
 * and intent.
 *
 * DEVELOPER NOTE: Include comments on the "what this does" and the "why" for
 * each override so that we can understand and remove any in the future.
 */

// TODO: See https://github.com/democrats/portal/compare/yehp/4329-bootstrap-prototype?expand=1
// for filling out this file. We're starting somewhat with a blank slate to
// make sure every style is needed and commented.

// bootstrap-material-design's radio/checkbox labels are awkwardly too light.
// Style the lables more like
// https://material-components.github.io/material-components-web-catalog/#/component/radio
.radio label,
.is-focused .radio label,
.radio-inline,
.is-focused .radio-inline,
.checkbox label,
.is-focused .checkbox label,
.checkbox-inline,
.is-focused .checkbox-inline,
.switch label,
.is-focused .switch label {
  color: rgba(0, 0, 0, 0.87);
}
.radio label:hover,
.radio label:focus,
.radio label:active,
.is-focused .radio label:hover,
.is-focused .radio label:focus,
.is-focused .radio label:active,
.radio-inline:hover,
.radio-inline:focus,
.radio-inline:active,
.is-focused .radio-inline:hover,
.is-focused .radio-inline:focus,
.is-focused .radio-inline:active,
.checkbox label:hover,
.checkbox label:focus,
.checkbox label:active,
.is-focused .checkbox label:hover,
.is-focused .checkbox label:focus,
.is-focused .checkbox label:active,
.checkbox-inline:hover,
.checkbox-inline:focus,
.checkbox-inline:active,
.is-focused .checkbox-inline:hover,
.is-focused .checkbox-inline:focus,
.is-focused .checkbox-inline:active,
.switch label:hover,
.switch label:focus,
.switch label:active,
.is-focused .switch label:hover,
.is-focused .switch label:focus,
.is-focused .switch label:active {
  color: #000;
}

// Form labels should be uppercase and bold
label,
.label {
  color: $gray-600;
  text-transform: uppercase;
  font-family: $dnc-font-bold;
}
// Space out form groups a bit more than the default of 1rem
.form-group {
  margin-bottom: 1.5rem;
}

// Except for form labels associated with checkboxes and radios
.form-check label {
  text-transform: none;
  font-family: $dnc-font-normal;
  font-size: 1rem;
}

// Django generates checkboxes inside of labels by default, which doesn't play well with
// bootstrap
.form-check label input {
  display: inline;
}

// Lighten font color to make it more obvious when
// content is placeholder, not value
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
// Prefer outlined form inputs (instead of default underline)
.form-control,
.custom-file-control,
input.form-control,
input.custom-file-control,
form .form-control,
form .custom-file-control {
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: $white;
  border: 1px solid rgba($black, 0.26);
  border-radius: 2px;
}
// Remove the input underline
.form-control,
.custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control,
.form-control:invalid,
.custom-file-control:invalid,
.form-control:read-only,
.custom-file-control:read-only,
fieldset[disabled][disabled] .form-control,
fieldset[disabled][disabled] .custom-file-control,
.form-control.disabled,
.disabled.custom-file-control,
.form-control:disabled,
.custom-file-control:disabled,
.form-control[disabled],
.custom-file-control[disabled] {
  background-image: none;
}
// Keep the input background white instead of transparent when it's focused
// (for forms that are in a non-white container)
.form-control:focus,
.custom-file-control:focus {
  background-color: $white;
}
// Include a right-hand caret on select inputs
// Adapted from https://css-tricks.com/styling-a-select-like-its-2019/
select.form-control:not([multiple]),
select.form-control:read-only:not([multiple]),
select.form-control:invalid:not([multiple]) {
  padding-right: 2rem;
  position: relative;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='18px'%3E%3Ctext x='0' y='10' fill='dimgray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.5rem 1rem;
  background-position: right center;
  background-clip: border-box;
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

// Make the breadcrumb's background transparent so it can be used anywhere
.breadcrumb {
  background: none;
}

// Set the Bootstrap navbar "elevation" to 4dp, as per Google's Material Design
// spec and Web Component implementation.
// https://material.io/design/environment/elevation.html#elevation-in-material-design
// https://material-components.github.io/material-components-web-catalog/#/component/elevation
.navbar {
  // TODO: Uncomment or delete this style. Assessing the original look for now.
  // box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
// Remove "uppercase" text-transform from Bootstrap nav links
.nav-link {
  text-transform: none;
}

// Revert Bootstrap's shrinking of text in cards
.card {
  font-size: 1rem;
}

// Remove the "slide down" effect from Bootstrap modals
.modal.fade .modal-dialog {
  transform: none;
}

// Uppercase table headers
.table th {
  text-transform: uppercase;
}

// it should be clear that disabled buttons aren't clickable
.btn[disabled] {
  cursor: default;
}

// The bootstrap-material-design theme barely dims the "filled/raised" button's
// background color when it's disabled, so still looks enabled, which is
// confusing. This grays out the button's background so it's more obvious that
// it's disabled.
// (The !important is a lazy not-ideal way to apply the tweak to both normal
// and hover states.)
.btn.btn-raised[disabled] {
  background-color: $dnc-grey !important;
  color: $dnc-grey-dark !important;
}

// If a table has rows which are both striped and alerts, the alert color wins
.table-striped tbody tr:nth-of-type(odd).alert-warning {
  @extend .alert-warning;
}

// Increase the clickable area for radio and checkboxes
.form-check-input {
  margin-top: 0.1rem;
  height: 1.2rem;
  width: 1.2rem;
}

// Adjust radio and checkbox labels to accommodate larger click area (see above)
.form-check-label {
  line-height: 1.2rem;
  margin-left: 0.5rem;
}

// This is a patch to allow Bootstrap's bold style to take affect. There's a
// problem with the GothamBook font file in that it can't be bolded with CSS,
// and it's why there's a separate GothamBold font.
// TODO: Delete this workaround when we've changed away from the Gotham font.
// It was a legacy DNC resource.
.font-weight-bold {
  font-family: "GothamBold";
}

// The Bootstrap legend has a default width of 100%. Material UI 5’s <Select>
// component expects it to be unset.
legend {
  width: auto;
}
