/* Styles for Jobs List page */
@import "dnc-colors";

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0em !important;
    display: block !important;
}

table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before {
    opacity: 0.7;
    margin-top: 2px;
}

table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:after {
    opacity: 1;
    margin-top: 2px;
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    margin-top: 1.05em;
    margin-left: 15px;
    margin-bottom: 0;
}

.sorting_disabled:before,
.sorting_disabled:after {
    visibility: hidden;
}

.sorting:before {
    color: white;
    opacity: 1;
}

.div--jobs-list-filters-container {
    display: inline-flex;
    align-items: baseline;
    gap: 5%;
}

.select--jobs-list-filter {
    display: block !important;
    min-width: 150px;
    width: auto;
    height: 2.156rem;
    color: inherit;
    background-color: transparent;
    font-family: "gothambook";
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
}

.btn--clear-filters {
    background: none;
    border: none;
    color: $dnc-blue-primary;
    cursor: pointer;
    min-width: 150px;

    &:hover, &:focus-visible {
        text-decoration: underline;
        color: #0077A7;
    }
}
