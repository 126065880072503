/* Overrides to material-ui’s Autocomplete component styling. */

@import "dnc-colors";

.dnc-mui-autocomplete {
  .MuiAutocomplete-tag {
    border-radius: 5px;

    &:not(.Mui-disabled):not(.MuiChip-colorError) {
      background-color: $dnc-blue-primary;
      color: $white;
    }
  }

  .MuiChip-deleteIcon {
    color: $white;
  }
}

.dnc-mui-autocomplete-job-labels {
  @extend .dnc-mui-autocomplete;
  
  .MuiInputBase-root {
    border-color: rgba(0, 0, 0, 0.26);
    border-radius: 2px;
  }
}
