/**
 * This file loads the font files to be used in the DNC Portal theme. Uses of
 * these fonts, such as in headings, are defined separately.
 */

@font-face {
  font-family: "GothamBold";
  src: url("/static/portal/fonts/Gotham-Bold.eot");
  src: url("/static/portal/fonts/Gotham-Bold.otf") format("otf"),
    url("/static/portal/fonts/Gotham-Bold.svg") format("svg"),
    url("/static/portal/fonts/Gotham-Bold.ttf") format("ttf"),
    url("/static/portal/fonts/Gotham-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamBook";
  src: url("/static/portal/fonts/GothamRounded-Book.otf") format("otf"),
    url("/static/portal/fonts/GothamBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$dnc-font-bold: "GothamBold", sans-serif;
$dnc-font-normal: "GothamBook", sans-serif;

:export {
  fontNormal: $dnc-font-normal;
}
