@import "dnc-colors";

.MuiSlider-rail {
  color: $dnc-grey-dark;
}

.MuiSlider-track .MuiSlider-thumb {
  color: $dnc-blue-dark;
}

.MuiSlider-valueLabel {
  color: $dnc-blue-primary;
}

.MuiPaper-root .MuiAccordionSummary-root .MuiPaper-elevation1 {
  box-shadow: none;
}

#edit_dataset_org_sharing {
  .MuiAccordionSummary-root {

    h3,
    .h5 {
      color: black;
    }
  }
}

#voter-file-match-task-form {
  .MuiAccordionSummary-content {
    color: $dnc-blue-primary;
  }

  .input-field-labels {
    font-size: 11px;
  }

  .required-matching-field {
    background-color: $portal-required-field;
  }

  .soft-required-matching-field {
    background-color: $portal-soft-required-field;
  }

  .optional-matching-field {
    background-color: $portal-optional-field;
  }
}